<template>
  <div class="menu-wrap"  @touchmove.prevent>
    <div class="close-wrap">
      <img src="../image/close.png" class="close-btn" @click="handleClose"/>
    </div>
    <div class="menu-item" :class="{active:sharedState.selectedMenuPath===item.mobileLinkUrl}"  :key="index" v-for="(item,index) in menuList" @click="handleMenuItemClick(item)">{{item.name}}</div>
  </div>
</template>

<script>
  import Store from '@/store/index.js'
  import {getMenuList} from '@/api/menu.js'
  export default{
    data(){
      return {
        sharedState:Store.state,
        activeMenuPath:'/',
        menuList:[]
      }
    },
    created() {
      getMenuList().then((data)=>{

        this.menuList = data.data
      })
    },
    methods:{
      handleClose(){
        this.$emit('close')
      },
      handleMenuItemClick(menuItem){
        Store.setSelectedMenuPath(menuItem.mobileLinkUrl)
        if(menuItem.mobileLinkUrl.indexOf('http://') != -1  || menuItem.mobileLinkUrl.indexOf('https://') != -1){
          window.location.href = menuItem.mobileLinkUrl
          return
        }
        this.$router.push({path:menuItem.mobileLinkUrl})
        this.handleClose()
      }
    }
  }
</script>

<style lang="scss">
  .menu-wrap{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0,.6);
    top:0;
    left: 0;
    z-index: 10000;
    
    .close-wrap{
      padding-top: 60px;
      padding-left: 34px;
      .close-btn{
        width: 40px;
      }
    }
    
    .menu-item{
      font-size: 24px;
      color: #b8b7b7;
      line-height: 80px;
      text-align: center;
      
      &:first-child{
        margin-top: 60px;
      }
      
      &.active{
        color:#fff;
      }
    }
  }
</style>
