import request from '@/utils/request'


/**
 * 获取菜单列表
 */
export function getMenuList() {
    return request({
        url: '/cms/api/portal/categories/140/children',
        method: 'get',
        params: {}
    })
}






