<template>
  <div class="portal-layout">
     <nav-bar></nav-bar>
     <keep-alive>
        <router-view v-if='$route.meta.keepAlive'></router-view>
     </keep-alive>
     <router-view v-if='!$route.meta.keepAlive'></router-view>
     <div class="copyright-wrap">
       <p class="copyright-info">Copyright © 2020-2021 GDCC(China) All Rights Reserved.</p>
       <p class="icp-info">
         <a href="http://beian.miit.gov.cn" target="_blank" style="color: rgb(255, 255, 255);">蜀ICP备18014798号-1</a>
         <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002001467" style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px; color: rgb(255, 255, 255);"><img
             src="../image/beian.png" style="float: left; margin-right: 5px; margin-left: 5px;">川公网安备 51019002001467号</a></p>
     </div>
  </div>
</template>

<script>
  import NavBar from '@/components/navbar.vue'
  export default{
    props:{
      paddingTop:{
        type:Number,
        default:30
      }
    },
    components:{
      NavBar
    }
  }
</script>

<style lang="scss">
  .portal-layout{
    padding-top: 54px;

    .copyright-wrap{
      // margin-top: 30px;
      background-color: #434343;
      color: #fff;
      padding: 10px 0px;
    }
    .copyright-info{
      font-size: 12px;
      text-align: center;
      line-height: 30px;
    }
    .icp-info{
      line-height: 30px;
      text-align: center;
      font-size: 12px;
    }
  }
</style>
