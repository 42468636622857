import axios from 'axios'

// createAndEdit an axios instance
const service = axios.create({
  baseURL: '', // url = base url + request url
  // timeout: 5000, // request timeout
  headers: { 'X-Requested-With': 'XMLHttpRequest' }
})

// 不循序重复请求 相同请求会阻塞
const pendingAjax = []
const fastClickMsg = '数据请求中，请稍后'
const CancelToken = axios.CancelToken
const removePendingAjax = (config, c) => {
  let url
  if (config.url.indexOf(config.baseURL) >= 0) {
    url = config.url + config.method
  } else {
    url = config.baseURL + config.url + config.method
  }
  const index = pendingAjax.findIndex(i => i === url)
  if (index > -1) {
    c ? c(fastClickMsg) : pendingAjax.splice(index, 1)
  } else {
    c && pendingAjax.push(url)
  }
}

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (config.method.toLowerCase !== 'get'.toLowerCase) {
      config.cancelToken = new CancelToken(c => {
        removePendingAjax(config, c)
      })
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    removePendingAjax(response.config)
    const res = response.data

    // // if the custom code is not 20000, it is judged as an error.
    // if (res.status !== 0) {
    //   const message =  res.msg || 'Error'
    //   alert(message)
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
    
    return res
    
  },
  error => {
    alert(error.message)
    return Promise.reject(error)
  }
)

export default service
