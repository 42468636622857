<template>
  <nav class="nav-wrap">
    <img src="../image/logo_quark.png" class="logo" />
    <!-- <div style="padding-left:10px;">
      <div style="font-size:14px;">夸克医药（中国）有限公司</div>
      <div style="font-size:9px;margin-top:3px;">Quark Pharmaceuticals(China)Co., Ltd.</div>
    </div> -->
    <div class="nav-btn-wrap">
      <div class="header-btn active">中文</div>
      <div class="space-line"></div>
      <div class="header-btn" @click="handleEnglishClick">EN</div>
      <div class="menu-btn" @click="handleOpenMenu"></div>
    </div>
    
    <nav-menu v-if="showNavMenu" @close="handleClose"></nav-menu>
    
  </nav>
</template>

<script>
  import NavMenu from './menu.vue'
  export default{
    components:{
      NavMenu
    },
    data(){
      return{
        showNavMenu:false
      }
    },
    methods:{
      handleClose(){
        this.showNavMenu = false
      },
      handleOpenMenu(){
        this.showNavMenu = true
      },
      handleEnglishClick(){
        window.location.href="/en/?locale=en-us"
      }
    },
    mounted() {
      
    }
  }
  
</script>

<style lang="scss">
  .nav-wrap {
    position: fixed;
    background-color: #fff;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    top: 0;
    left: 0;
    z-index: 100;

    .logo {
      margin-left: 12px;
      width: 186px;
    }

    .nav-btn-wrap {
      display: flex;
      align-items: center;
      
      .header-btn {
        color: #9d9d9d;
        font-size: 12px;
        padding: 4px 10px;
        height: 40px;
       
        line-height: 40px;

        &.active {
          height: 20px;
          line-height: 20px;
          background-color: #f36314;
          padding: 0px 7px;
          color:#fff;
          border-radius: 2px;
        }
      }
      
      .space-line{
        margin-left: 10px;
        width: 1px;
        height: 14px;
        background-color: #b3b3b3;
      }
      
      .menu-btn{
        height: 40px;
        width: 50px;
        background-image: url(../image/menu.png);
        background-size: 27px 18px;
        background-position:center;
        background-repeat: no-repeat;
      }

    }

  }
</style>
